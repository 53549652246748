import { FC, useCallback } from 'react'
import { isEqual } from 'lodash'
import classNames from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'

import { setSidebarClosed } from 'store/global'
import { EComingSoonItemVariants, TSVG } from 'types'
import { ComingSoonItem, Text } from 'components'
import { useAppDispatch } from 'libraries/redux'

import styles from './SidebarMenu.module.scss'

type TMenuItem = {
  Icon: TSVG
  text: string
  soon?: boolean
  open?: boolean
  setOpen: (arg: boolean) => void
  navigateTo: string
}

const SidebarMenuItem: FC<TMenuItem> = ({ setOpen, Icon, navigateTo, soon, text, open }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const onNavigationClick = useCallback(() => {
    if (open) {
      dispatch(setSidebarClosed())
    } else {
      setOpen(false)
    }
  }, [dispatch, open, setOpen])

  const menuItemClassName = (isActive?: boolean) =>
    classNames(styles.wrapper__item, {
      [styles.wrapper__closed__item]: !open,
      [styles.without_soon]: !soon && !open,
      [styles.wrapper__active_item]: isActive && navigateTo,
    })

  const menuItemTextClassName = classNames(styles.wrapper__item__text, {
    [styles.wrapper__closed__item__text]: !open,
    [styles.wrapper__item__box__presale]: soon,
    [styles.wrapper__active_item__text]: isEqual(navigateTo, location.pathname) && navigateTo,
  })

  const comingSoonItemClassNames = classNames(styles.wrapper__item__box__soon, {
    [styles.wrapper__closed__item__badge]: !open,
  })

  if (text === 'Setting') {
    return null
  }

  return (
    <NavLink
      onClick={onNavigationClick}
      to={navigateTo}
      target={navigateTo.includes('https') ? '_blank' : '_self'}
      className={({ isActive }) => menuItemClassName(isActive)}
    >
      <div className={styles.wrapper__item__box}>
        <Icon />

        <Text tagName='span' text={text} className={menuItemTextClassName} />
      </div>

      {soon && (
        <ComingSoonItem
          isDynamic
          closed={!open}
          text='Soon'
          className={comingSoonItemClassNames}
          variant={EComingSoonItemVariants.Soon}
        />
      )}
    </NavLink>
  )
}

export default SidebarMenuItem
